import pageConstants from 'modules/page/config/pageConstants'

export default {
  admin: [
    ...pageConstants.types.map(type => ({
      path: `page-category/${type}`,
      name: `page-category-${type}`,
      meta: { type },
      component: () => import('@/modules/page/views/pageCategoryTree.vue'),
    })),

    // 頁面列表
    ...pageConstants.types.map(type => ({
      path: `page/${type}`,
      name: `page-${type}`,
      meta: { type },
      component: () => import('@/modules/page/views/pageList.vue'),
    })),

    // 頁面表單
    ...pageConstants.types.map(type => ({
      path: `page/${type}/update/:target`,
      name: `page-update-${type}`,
      meta: {
        formMode: 'update',
        type,
      },
      component: () => import('@/modules/page/views/pageForm.vue'),
    })),
  ],
}